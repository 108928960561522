$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-spotify-green: #1DB954;

// home page
$color-alabaster: #FBFBFB;
$color-onyx: #0F0F0B;

// inner pages
$color-purple: #43346D;
$color-midnight: #011436;
$color-midnight-75: #011436bf;
// fonts
$primary-font: "canada-type-gibson", sans-serif;
