@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $color-black;
	font-family: $primary-font;
	font-weight: 400;
	font-size: 16px;
	line-height: 1;
	letter-spacing: .075em;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}
 
body {
	color: $color-white;
	width: 100%;
	overflow-x: hidden;
}

a {
	color: inherit;
	font-family: $primary-font;

	@include hover-focus {
		opacity: .6;
	}
}

h1,
h2,
h3 {
	font-family: $primary-font;
	font-weight: 600;
	letter-spacing: .075em;
}

.btn {
	border-radius: rems(10);
	font-size: rems(20);
	text-transform: uppercase;
	padding: rems(9) rems(12);
	display: inline-flex;
	align-items: center;

	i {
		color: $color-spotify-green;
		font-size: rems(30);
		margin-right: rems(10);
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.6;
	}
}

#main-bg {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	main {
		flex: 1;
	}


	&.step-1 {
		background-image: url("../img/bg/bg-home.jpg");
	}

	&.step-2-3 {
		background-image: url("../img/bg/bg-inner.jpg");
	}
}

header {
	nav {
		display: flex;
		justify-content: center;
		margin: rems(20) 0;

		h1#logo {
			text-transform: uppercase;
			font-size: rems(22);

			&.step-1 {
				color: $color-onyx;
			}

			&.step-2-3 {
				color: $color-alabaster;
			}
		}
	}
}

main {

	section.step-1 {
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;

			img {}

			p {
				font-size: rems(25);
				color: $color-onyx;
				text-align: center;
				line-height: rems(50);
				margin-bottom: rems(20);

				span {
					font-weight: 600;
				}
			}

			.btn-wrapper {
				display: flex;
				flex-direction: column;

				p {
					font-size: rems(20);
					color: $color-onyx;
					text-align: center;
					margin-bottom: rems(20);
				}

				a.btn {
					background-color: $color-onyx;
					font-size: rems(15);
					padding-right: rems(30);
					margin-bottom: rems(20);
				}
			}

			.terms {
				display: flex;
				align-items: flex-start;

				input[type="checkbox"] {
					margin: 4px;
					margin-right: 30px;

					&::before {
						content: '';
						display: inline-block;
						width: 14px;
						height: 14px;
						background-color: $color-white;
						border: 2px solid $color-black;
						border-radius: 0;
						vertical-align: middle;
						padding: 3px;
						// transform: translate(-20%, -20%);
					}

					&:checked::before {
						content: '\f00c';
						font: var(--fa-font-solid);
						font-size: 16px;
						color: $color-black;
					}
				}

				p {
					text-align: left;
					font-size: rems(18);
					line-height: 1em;
				}
			}
		}
	}

	section.step-2 {
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			p {
				color: $color-alabaster;
				font-size: rems(25);
				text-align: center;
			}

			.playlist-gen {
				width: 100%;

				#builder {
					.boxes-wrap {
						width: 100%;
						// max-width: rems(800);
						padding: rems(40) 0;
						margin: 0 auto;
						display: flex;
						align-items: flex-start;
						gap: rems(20);

						@include tablet-down {
							flex-direction: column;
						}

						.arrow {
							width: rems(120);

							@include tablet-down {
								align-self: center;
							}
						}

						.col {
							flex: 1;
							border-radius: rems(10);
							background-color: $color-midnight-75;
							font-size: rems(20);
							height: rems(525);
							position: relative;

							@include tablet-down {
								min-height: rems(180);
								max-height: rems(180);
								width: 100%;
							}

							ul {
								list-style: none;
								padding: rems(24);
								margin: 0;
								position: absolute;
								top: 0;
								bottom: 0;
								left: 0;
								right: 0;
								height: 100%;
								overflow-y: auto;

								&::-webkit-scrollbar {
									width: rems(8);
								}

								&::-webkit-scrollbar-thumb {
									background: $color-alabaster;
									width: rems(8);
									border-radius: rems(4);
								}

								&::-webkit-scrollbar-track {
									background: transparent;
								}
							}

							li {
								margin: rems(5) 0;
								display: flex;
								justify-content: space-between;
								padding: rems(4);
								border-radius: rems(5);
								transition: background-color .3s ease-in-out;

								@include hover-focus {
									background-color: #ffffff40;
								}

								.actions {
									display: flex;
									align-items: center;
									justify-content: center;
								}

								span {
									text-align: left;
									pointer-events: none;
								}

								i {
									display: flex;
									justify-content: center;
									align-items: center;
									width: 1.5em;
									height: 1.5em;
									border-radius: 50%;
									font-size: rems(12);
									border: 1px solid $color-alabaster;
								}
							}
						}

						#playlist li {
							cursor: pointer;
						}
					}
				}
			}


			a.btn {
				background-color: $color-purple;
				font-size: rems(20);
				padding: rems(16);
			}
		}
	}

	section.step-3 {
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				font-size: rems(26);
				text-align: center;
			}

			h2 {
				font-size: rems(39);
				text-transform: uppercase;
				font-weight: 400;
				text-align: center;
			}

			.content-wrapper {
				max-width: rems(505);
				width: 100%;
				background-color: $color-midnight-75;
				border-radius: rems(10);
				margin: rems(20) 0 rems(40);
				display: flex;
				align-items: center;
				justify-content: center;

				a {
					margin: rems(90);

					@include tablet-down {
						margin: rems(60);
					}

					@include phone-down {
						margin: rems(30);
					}

					img {}
				}

				//temp
			}


			a.btn {
				background-color: $color-purple;
				font-size: rems(20);
			}
		}
	}
}

footer {
	.container {
		text-align: center;
		font-size: rems(10);
		color: $color-onyx;
		opacity: .7;

		&.step-2-3 {
			color: $color-alabaster;
		}
	}
}